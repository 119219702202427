.fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  inline-size: 100%;
  position: relative;
}

.trigger {
  appearance: none;
  block-size: 44px;
  inline-size: 100%;
  border: thin solid var(--color-border, #ccc);
  border-radius: var(--input-border-radius, 0);
  background: var(--input-background, #fff);
  padding-inline: 1rem;
  padding-block: 0;
  display: inline-flex;
  gap: 0.5rem;
  line-height: 1;
  align-items: center;
  justify-content: space-between;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: none;
    border-color: var(--color-focus, #333);
  }

  &:hover {
    cursor: pointer;
  }

  &[data-placeholder] {
    color: var(--color-text-disabled, inherit);
  }
}

.icon {
  color: var(--color-text, inherit);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.content {
  overflow: hidden;
  background: var(--input-background, #fff);
  border: thin solid var(--color-border, #ccc);
  inline-size: var(--radix-select-trigger-width);
}

.viewport {
  padding: 0.5rem;
}

.label {
  padding: 0 1.5rem;
}

.separator {
  block-size: 1px;
  background-color: var(--color-border, #ccc);
  margin: 0.5rem;
}

.scroll-button {
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: 25px;
  background-color: white;
  color: var(--violet-11);
  cursor: default;
}

.item {
  display: flex;
  align-items: center;
  block-size: 44px;
  padding-inline: 2rem 1rem;
  position: relative;
  user-select: none;
  color: var(--color-text-subdued, inherit);

  &[data-disabled] {
    color: var(--color-text-disabled, inherit);
    pointer-events: none;
  }

  &[data-highlighted] {
    outline: thin solid var(--color-focus, #333);
    z-index: 1;
    outline-offset: calc(0.5rem - 1px);
    color: var(--color-text, inherit);
  }
}

.text {
  color: var(--color-text, inherit);
}

.indicator {
  position: absolute;
  inset-inline-start: 0;
  inline-size: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

$delay: var(--loader-delay, 1s);
$duration-show: var(--loader-display-duration, var(--duration-small, 0.2s));
$duration: var(--loader-duration, 1s);
$show-animation-name: fade-in;
$show-animation: $show-animation-name $duration-show ease-in $delay;
$wait-animation-name: wait;
$wait-animation: $wait-animation-name $delay;
$spin-animation-name: spin;
$spin-animation: $spin-animation-name var(--loader-duration, 1s) linear infinite;

.container {
  --delay: var(--loader-delay, 1s);
  --duration: var(--loader-display-duration, var(--duration-small, 0.2s));

  display: flex;
  justify-content: center;
  align-items: center;
  block-size: 100%;
  inline-size: 100%;
  flex: 1 1 auto;
  padding: var(--loader-padding, 0);
  animation: $wait-animation, $show-animation;
  pointer-events: none;
}

.loader {
  aspect-ratio: 1;
  inline-size: 100%;
  max-inline-size: var(--loader-size, 3rem);
  block-size: 100%;
  max-block-size: var(--loader-size, 3rem);
  border: var(--loader-thickness, 0.25rem) solid
    var(--loader-color, var(--accent-color-primary, var(--color-text, #333)));
  border-block-end-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: $spin-animation;
}

@keyframes #{$spin-animation-name} {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes #{$wait-animation-name} {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes #{$show-animation-name} {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

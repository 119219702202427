@use 'mixins' as *;

.root {
  --options-gap: 1rem;

  font-size: var(--font-size-body, 1rem);
  inline-size: calc(100vw - var(--page-margin-2x, 2.5rem));
  inline-size: calc(100vi - var(--page-margin-2x, 2.5rem));
  max-inline-size: var(--tabs-max-width, var(--content-max-width, 1440px));
  margin-inline: auto;
}

.retailers {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--find-retailers-section-gap, 4rem);
}

.search-container,
.result-container {
  inline-size: 100%;
  display: grid;
  grid-template-columns: repeat(var(--find-retailers-grid-columns, 12), 1fr);
  gap: var(--find-retailers-grid-row-gap, var(--find-retailers-grid-gap, 4rem))
    var(--find-retailers-grid-gap, 1.5rem);
}

.search-label {
  display: none;
}

.search-fieldset {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 0.5rem;
  block-size: 44px;
  border: thin solid
    var(--find-retailers-border-color, var(--color-border, #ccc));
  border-radius: var(
    --find-retailers-input-radius,
    var(--input-border-radius, 0)
  );
  background: var(
    --find-retailers-input-background,
    var(--input-background, #fff)
  );
  inline-size: 100%;
}

.search-input {
  border: 0;
  background: transparent;
  border-radius: var(--find-retailers-input-radius, 0);
  padding-inline: 1rem;
  flex-grow: 1;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: thin solid
      var(--find-retailers-focus-color, var(--color-focus, #333));
  }
}

.map-link {
  display: inline-flex;
  align-items: center;

  svg {
    margin-inline-end: 0.5em;
  }
}

.search-button {
  aspect-ratio: 1;
  block-size: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--find-retailers-button-background, transparent);
  border-radius: var(--find-retailers-button-radius, 0);

  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: thin solid
      var(--find-retailers-focus-color, var(--color-focus, #0078d4));
  }

  > svg {
    transition: opacity 0.1s;
  }
}

.icon-link svg {
  margin-inline: 0 0.5em;
}

.search-button:disabled {
  svg {
    opacity: 0.5;
  }
}

.icon-link {
  display: inline-flex;
  align-items: center;

  &.end {
    svg {
      margin-inline: 0.5em 0;
    }
  }
}

.search-clear {
  &:disabled {
    svg {
      opacity: 0;
    }
  }
}

.search-message {
  font-size: var(--find-retailers-message-font-size, inherit);
  color: var(--find-retailers-message-color, inherit);
  text-align: center;
  min-block-size: 1lh;
}

.search-input-container {
  grid-column: 3 / span 4;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: var(--options-gap);

  @include breakpoint-max(small) {
    grid-column: 1 / span 12;
    grid-row: 1;
  }
}

.search-options {
  grid-column: 7 / span 4;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: var(--options-gap);

  @include breakpoint-max(small) {
    grid-column: 1 / span 12;
    grid-row: 2;
  }
}

.radius-help-text {
  inline-size: 100%;
  color: var(--color-text-subdued, var(--color-text, inherit));
}

.international-input {
  margin-block-start: 1rem;
}

.result-stores {
  position: relative;
  grid-column: 1 / span 5;
  min-block-size: var(--map-height, inherit);

  @include breakpoint-max(small) {
    grid-column: 1 / span 12;
    grid-row: 2;
  }
}

.result-list {
  &:empty {
    display: none;
  }
}

.result-map {
  position: relative;
  grid-column: 7 / span 6;

  @include breakpoint-max(medium) {
    grid-column: 6 / span 7;
  }

  @include breakpoint-max(small) {
    grid-column: 1 / span 12;
    grid-row: 1;
  }
}

.accordion-label {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
}

.accordion-title,
.accordion-subtitle {
  display: block;
  inline-size: 100%;
}

.accordion-title {
  font-size: var(--find-retailers-title-font-size, inherit);
  color: var(--find-retailers-title-color, var(--color-text, inherit));
  font-weight: var(
    --find-retailers-title-font-weight,
    var(--font-weight-normal, inherit)
  );
}

.accordion-subtitle {
  font-size: var(--find-retailers-subtitle-font-size, inherit);
  color: var(
    --find-retailers-subtitle-color,
    var(--color-text-subdued, inherit)
  );
  font-weight: var(
    --find-retailers-subtitle-font-weight,
    var(--font-weight-light, inherit)
  );
}

.accordion-content {
  display: flex;
  flex-flow: column nowrap;

  & > * + * {
    border-block-start: thin solid
      var(--accordion-content-border-color, var(--color-border, #eee));
    padding-block-start: var(--find-retailers-accordion-content-gap, 1.5rem);
    margin-block-start: var(--find-retailers-accordion-content-gap, 1.5rem);
  }
}

.location {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--find-retailers-accordion-content-gap, 1.5rem);
}

.address {
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
}

.store-sells {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;

  &-label {
    margin: 0;
    font-size: var(--find-retailers-subtitle-font-size, inherit);
    color: var(
      --find-retailers-subtitle-color,
      var(--color-text-subdued, inherit)
    );
    font-weight: var(--find-retailers-subtitle-font-weight, inherit);
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 1em;
  }

  &-item {
    padding: 0;
    margin: 0;
    display: inline;
    position: relative;

    &:not(:last-of-type)::after {
      content: '|';
      position: absolute;
      inset-inline-end: -0.66em;
    }
  }
}

.link {
  text-decoration: underline;
  text-decoration-color: var(
    --color-underline-inactive,
    var(--color-text, inherit)
  );
  text-decoration-skip-ink: auto;
  text-decoration-thickness: var(--underline-thickness, 1px);
  text-underline-offset: var(--underline-offset, 2px);

  &:hover {
    text-decoration-color: var(--color-underline, var(--color-text, inherit));
  }
}

.online-stores {
  &-empty {
    color: var(--color-text, inherit);
    text-align: center;
    min-block-size: 1lh;
    inline-size: 100%;
  }

  &-list {
    max-inline-size: 30rem;
    inline-size: 100%;
    margin: 0;
    margin-inline: auto;
    list-style: none;
    padding: 0;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex-flow: column nowrap;
  }

  &-item {
    margin: 0;
    inline-size: 100%;
  }

  &-link {
    padding: 0.5lh 0;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    inline-size: 100%;
    border-block-end: thin solid var(--color-border-light, #eee);
    padding-block: 1rem 1.5rem;

    :first-of-type > & {
      padding-block-start: 1.5rem;
      border-block-start: thin solid var(--color-border-light, #eee);
    }
  }
}

.icon-external {
  color: var(--color-text-subdued, inherit);
}

.loader-map,
.loader-list,
.loader-online {
  position: absolute;
  inset: 0;
  background: rgb(255 255 255 / 80%);
  min-block-size: var(--map-height, inherit);
}

.loader-map {
  max-block-size: var(--map-height, inherit);
}

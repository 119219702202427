@use 'mixins' as *;

.slider-block {
  aspect-ratio: var(--slider-block-aspect-ratio);
}

.swiper {
  div[class*='swiper-slide'] {
    div[class*='hero-block']:not([class*='grid']) {
      margin: 0;
    }
  }

  div[class='swiper-button-prev']:active,
  div[class='swiper-button-next']:active {
    transform: scale(0.96);
  }

  div[class='swiper-button-prev']::after,
  div[class='swiper-button-next']::after {
    filter: drop-shadow(0 3px 2px rgb(0 0 0 / 70%));
  }

  span[class='swiper-pagination-bullet'] {
    @include hover {
      opacity: var(--swiper-pagination-bullet-opacity, 1);
    }
  }
}

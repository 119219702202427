.root {
  display: flex;
  flex-flow: column nowrap;
  max-inline-size: var(--tabs-max-width, 1440px);
  margin-inline: auto;
  gap: var(--tabs-root-gap, 1.5rem);
}

.list {
  justify-content: center;
  flex-shrink: 0;
  display: flex;
  gap: var(--tabs-list-gap, 1.5rem);
}

.trigger {
  display: flex;
  block-size: var(--tabs-trigger-height, 2rem);
  align-items: center;
  padding-inline: var(--tabs-trigger-padding, 1.5rem);
  font-family: var(--tabs-trigger-font, inherit);
  font-size: var(--tabs-trigger-size, inherit);
  color: var(
    --tabs-color-action-inactive,
    var(
      --color-action-inactive,
      var(--tabs-color-action, var(--color-action, inherit))
    )
  );
  position: relative;

  &::after {
    content: '';
    display: block;
    border-block-end: var(--tabs-marker-height, 2px) solid transparent;
    position: absolute;
    inset-inline: var(--tabs-marker-inset, 33px);
    inset-block-end: 0;
  }

  &:hover {
    cursor: pointer;
    color: var(
      --tabs-color-action-hover,
      var(
        --color-action-hover,
        var(--tabs-color-action-active, var(--color-action-active, inherit))
      )
    );
  }

  &[data-state='active'] {
    color: var(--tabs-color-action-active, var(--color-action-active, inherit));
  }

  &[data-state='active']::after {
    border-color: var(
      --tabs-color-action-active,
      var(--color-action-active, inherit)
    );
  }

  &:focus-visible {
    outline: thin solid
      var(--tabs-color-focus-outline, var(--color-focus-outline, #ddd));
    outline-offset: var(--tabs-focus-offset, var(--focus-offset, 0.125rem));
  }
}

.paragraph {
  text-align: var(--text-align, start);
  font-family: var(--font-family, --font-family-hero);
  color: var(--text-color, #000);
  margin-block: var(--margin-block, 0);

  &__p1-preamble {
    font-weight: var(--font-weight-p1, 400);
    font-size: var(--font-size-p1, 0.875rem);
    letter-spacing: var(--letter-spacing-p1, 0);
    line-height: var(--line-height-p1, 1);
  }

  &__p2-paragraph {
    font-weight: var(--font-weight-p2, 400);
    font-size: var(--font-size-p2, 1.25rem);
    letter-spacing: var(--letter-spacing-p2, 0);
    line-height: var(--line-height-p2, 1);
  }

  &__p3-paragraph {
    font-weight: var(--font-weight-p3, 400);
    font-size: var(--font-size-p3, 1.25rem);
    letter-spacing: var(--letter-spacing-p3, 0);
    line-height: var(--line-height-p3, 1);
  }

  &__sub-heading {
    font-weight: var(--font-weight-subheading, 400);
    font-size: var(--font-size-subheading, 0.625rem);
    letter-spacing: var(--letter-spacing-subheading, 0);
    line-height: var(--line-height-subheading, 1);
    text-transform: uppercase;
  }
}

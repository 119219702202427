.root {
  --transition-time: var(--accordion-transition-time, 0.3s);

  display: block;
  border-block-end: thin solid
    var(--accordion-border-color, var(--color-border, #ddd));

  &:first-child {
    border-block-start: thin solid
      var(--accordion-border-color, var(--color-border, #ddd));
  }
}

.trigger {
  position: relative;
  inline-size: 100%;
  cursor: pointer;
  padding-block: calc(var(--accordion-trigger-padding, 1.5rem) - 0.5rem)
    var(--accordion-trigger-padding, 1.5rem);

  &:focus-visible {
    outline: thin solid
      var(--find-retailers-focus-color, var(--color-focus, #0078d4));
  }

  &:focus {
    outline: none;
  }

  .root:first-child & {
    padding-block-start: var(--accordion-trigger-padding, 1.5rem);
  }
}

.indicator {
  inline-size: var(--accordion-indicator-size, 16px);
  block-size: var(--accordion-indicator-size, 16px);
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: calc(
    50% - var(--accordion-indicator-size-half, 8px) - 0.25rem
  );
  transition: transform var(--transition-time, 0) ease-out;
  transform-origin: var(--accordion-indicator-size-half, 8px)
    var(--accordion-indicator-size-half, 8px);

  [aria-expanded='true'] & {
    transform: rotate(var(--accordion-indicator-rotation, -180deg));
  }

  & > svg,
  & > img {
    inline-size: var(--accordion-indicator-size, 16px);
    block-size: var(--accordion-indicator-size, 16px);
  }
}

.content-container {
  overflow: visible hidden;

  &[data-state='open'] {
    animation: open var(--transition-time, 0) cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='closed'] {
    animation: close var(--transition-time, 0) cubic-bezier(0.87, 0, 0.13, 1);
  }
}

.content {
  padding-block: var(--accordion-content-padding-top, 0)
    var(--accordion-content-padding-bottom, 3rem);
  font-size: var(--accordion-content-font-size, inherit);
}

@keyframes open {
  from {
    block-size: 0;
  }

  to {
    block-size: var(--radix-accordion-content-height);
  }
}

@keyframes close {
  from {
    block-size: var(--radix-accordion-content-height);
  }

  to {
    block-size: 0;
  }
}

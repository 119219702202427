.fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  gap: var(--checkbox-gap, 0.5rem);
  inline-size: 100%;
}

.root {
  background: var(--input-background, #fff);
  inline-size: 1.5rem;
  block-size: 1.5rem;
  border: thin solid var(--border-color, #ccc);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0;

  &:hover {
    background-color: var(--violet-3);
  }
}

.indicator {
  color: var(--color-text, inherit);
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  color: var(--color-text, inherit);
  flex-shrink: 1 1;
}

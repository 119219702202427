.navigation-menu-list {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  background-color: var(--navigation-menu-bg-color, #fff);
  padding: 0;
}

.viewport-position {
  position: absolute;
  inline-size: 100%;
  inset-block-start: 100%;
  inset-inline-start: 0;
  z-index: 12;
  box-shadow: 0 15px 20px -20px rgb(0 0 0 / 66.6%);
}

.navigation-menu-viewport {
  position: relative;
  inline-size: 100%;
  background-color: var(--navigation-menu-bg-color, #fff);
  transition: transform 800ms ease;
  overflow: hidden;
}

.navigation-menu-viewport[data-state='open'] {
  animation: scale-in 800ms ease;
  block-size: var(--radix-navigation-menu-viewport-height);
}

.navigation-menu-viewport[data-state='closed'] {
  animation: scale-out 800ms ease;
  block-size: 0;
}

@keyframes scale-in {
  from {
    block-size: 0;
  }

  to {
    block-size: var(--radix-navigation-menu-viewport-height);
  }
}

@keyframes scale-out {
  from {
    block-size: var(--radix-navigation-menu-viewport-height);
  }

  to {
    block-size: 0;
  }
}

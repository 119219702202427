@use 'mixins' as *;

.grid-container-block {
  --grid-margin: var(--spacing-small, 16px);

  @include breakpoint-min(small) {
    --grid-max-width: calc(100vw - 40px);
  }

  &__full-width {
    --grid-max-width: 'unset';
    --grid-margin: 0;
  }
}

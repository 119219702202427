.figure {
  background-size: cover;
  aspect-ratio: var(--aspect-ratio);
  position: relative;
  margin: 0;

  img {
    position: absolute;
    inline-size: 100%;
    block-size: 100%;
  }

  &--background {
    position: absolute;
    inset: 0;
  }
}

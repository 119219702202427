@use 'mixins' as *;

.figure {
  background-image: var(--background-image);
  background-size: cover;
  background-color: var(--background-color);
  position: relative;

  @include portrait {
    aspect-ratio: var(--aspect-ratio-portrait);
  }

  @include landscape {
    aspect-ratio: var(--aspect-ratio-landscape);
  }

  &--background {
    position: absolute;
    inset: 0;
  }
}

.wrapper {
  inline-size: 100%;
  block-size: 100%;
}

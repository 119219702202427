@use 'mixins' as *;

.footer {
  inline-size: 100%;
  margin: auto 0 0;
  padding: 0;
  background-color: var(--footer-bg-color, transparent);
}

.outer {
  @include content-width;

  padding-block: var(--footer-spacing-l, 3rem);
}

.logo-link {
  position: relative;
  display: block;
  min-block-size: 1rem;
  min-inline-size: 1rem;
  max-block-size: var(--footer-logo-size, 3rem);
  max-inline-size: var(--footer-logo-size, 3rem);
  margin-block-end: 1.25rem;
  color: var(--footer-logo-color, #fff);

  img {
    object-fit: contain;
  }
}

div.left-section {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.25rem;
}

.bottom-intro,
.social-media-label,
.desktop-menu-title {
  font-size: var(--footer-heading-font-size, 1.125rem);
  line-height: var(--footer-heading-line-height, 1.625rem);
  font-weight: var(--footer-font-weight-bold, 700);
  max-inline-size: 28.75rem;
  color: var(--footer-text-color, #fff);
  font-family: var(--footer-font-family-heading);
}

.desktop-menu-title {
  margin-block-end: 0.5rem;
}

.desktop-menu-item > a,
.accordion-content-wrapper,
.brand-links,
.cookie-settings-btn {
  margin-inline-start: -0.5rem;
}

.cookie-settings-btn {
  border: none;
  background-color: transparent;
  inline-size: fit-content;

  @include hover {
    cursor: pointer;
  }
}

p.markdown {
  color: var(--footer-text-color, #fff);
  font-family: var(--footer-font-family-regular);
  font-weight: var(--footer-font-weight-regular, 400);
  padding: 0.375rem 0;

  & > a {
    color: var(--footer-text-color, #fff);
    padding: 0.375rem 0;

    @include hover {
      color: var(--footer-text-color-hover, #fff);
    }
  }

  @include hover {
    color: var(--footer-text-color, #fff);
  }
}

.brand-links {
  display: flex;
  flex-wrap: wrap;
  max-inline-size: 31.25rem;

  & > *:not(:last-child)::after {
    content: '|';
    position: absolute;
    inset-inline-end: -2.5px;
  }
}

.link,
.cookie-settings-btn {
  color: var(--footer-text-color, #fff);
  position: relative;
  padding: 0.75rem 0.5rem;
  text-decoration: none;
  font-family: var(--footer-font-family-regular);
  font-weight: var(--footer-font-weight-regular, 400);

  @include breakpoint-min(small) {
    padding: 0.375rem 0.5rem;
  }
}

.link,
.brand-links,
.cookie-settings-btn {
  @include hover {
    color: var(--footer-text-color-hover, #fff);
  }
}

.social-media-section {
  display: flex;
  flex-flow: column nowrap;
  gap: var(--footer-spacing-s, 1rem);
  margin-block-start: var(--footer-spacing-s, 1rem);
}

.social-icons-wrapper {
  display: flex;
  padding-block-end: var(--footer-spacing-l, 3rem);

  @include breakpoint-min(small) {
    padding-block-end: 0;
  }
}

.social-icons-link {
  inline-size: 2rem;
  block-size: 2rem;
  position: relative;

  &:not(:last-of-type) {
    margin-inline-end: var(--footer-spacing-s, 1rem);
  }
}

div.grid {
  @include breakpoint-min(small) {
    grid-template-columns: 0.9fr 1.1fr;
  }
}

div.right-section {
  @include breakpoint-min(small) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    gap: 50px;
  }

  @include breakpoint-min(large) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.mobile-menu-accordion {
  --accordion-content-padding-bottom: 1rem;

  @include breakpoint-min(small) {
    display: none;
  }
}

.accordion-content-wrapper {
  display: flex;
  flex-flow: column nowrap;
}

.accordion-trigger {
  position: relative;
  font-weight: var(--footer-font-weight-bold, 500);
  color: var(--footer-text-color, #fff);

  --accordion-indicator-rotation: 45deg;
  --accordion-indicator-size: 1.25rem;

  & > span svg g {
    stroke: var(--footer-text-color, #fff);
  }
}

.desktop-menu {
  display: none;

  @include breakpoint-min(small) {
    display: inherit;
  }
}

.desktop-menu-item {
  display: flex;
  flex-flow: column nowrap;

  & :last-child {
    align-items: flex-end;
  }
}

.footer-end-container {
  margin-block-start: var(--footer-spacing-l, 3rem);

  @include breakpoint-min(small) {
    margin-block-start: 0;
  }
}

.market-picker-toggler {
  border: none;
  border-radius: 3px;
  background-color: #fff;
  inline-size: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  margin-block-start: var(--footer-spacing-s, 1rem);
  cursor: pointer;

  &:hover {
    filter: drop-shadow(0 2px 5px rgb(0 0 0 / 10%));
  }
}

.toggler-text-container {
  display: flex;
  flex-flow: column nowrap;

  span:last-child {
    font-size: 0.75rem;
    color: #b2b2b2;
  }
}

.triangle {
  inline-size: 0;
  block-size: 0;
  border-inline-start: 5px solid transparent;
  border-inline-end: 5px solid transparent;
  border-block-start: 5px solid black;
}

@use 'mixins' as *;

.container {
  position: relative;

  &.sticky {
    position: sticky;
    inset-block-start: calc(var(--header-height, 0) + var(--map-offset, 0));
  }

  inline-size: var(--map-width, 100%);
  block-size: var(--map-height, 32rem);
}

.info-link {
  vertical-align: middle;
  text-decoration-skip-ink: auto;
  display: flex;
  align-items: center;
  gap: 0.125rem;
}

.title {
  font-weight: bold;

  &:empty {
    display: none;
  }
}

.address {
  margin-block-start: 0.25rem;
  display: flex;
  flex-direction: column;

  &:empty {
    display: none;
  }

  span {
    &:empty {
      display: none;
    }
  }
}

.website {
  margin-block-start: 1rem;

  &:empty {
    display: none;
  }
}

.map-link {
  margin-block-start: 1rem;

  &:empty {
    display: none;
  }
}

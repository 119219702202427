@use 'mixins' as *;

.navigation-menu-trigger {
  @include hover {
    cursor: pointer;
  }
}

.navigation-menu-content-list {
  @include grid;

  padding-inline: 0;
  list-style: none;
}

.navigation-menu-link,
.navigation-menu-trigger {
  position: relative;
  font-weight: var(--navigation-menu-font-weight, 400);
  font-size: var(--navigation-menu-font-size, 1rem);
  letter-spacing: var(--navigation-menu-letter-spacing, 0);
  color: var(--navigation-menu-text-color, #000);
  padding: var(--navigation-menu-link-padding, 0 0);
  text-decoration: none;
  text-transform: capitalize;
  transition: 0.2s linear;

  &:empty {
    display: none;
    padding: 0;
  }

  @include hover {
    color: var(--navigation-menu-hover-color, #000);
  }
}

.navigation-menu-content {
  padding: var(--navigation-menu-padding, 3rem);
}

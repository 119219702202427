@use 'mixins' as *;

.swiper {
  div[class*='swiper-slide'] {
    div {
      margin: 0;
    }
  }
}

.slider-block {
  position: relative;

  .swiper-buttons {
    position: absolute;
    inline-size: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    block-size: var(--swiper-navigation-size);
    inset-block-start: calc(100% - 30px);
    z-index: 10;
  }

  div[class*='swiper-pagination'] {
    block-size: var(--swiper-navigation-size);
    inline-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  button[class*='swiper-button-prev']::after,
  button[class*='swiper-button-next']::after {
    opacity: 0;
  }

  button[class*='swiper-button-prev'],
  button[class*='swiper-button-next'] {
    background: url('/assets/gfx/image-slider-arrow-fill.svg') center center
      no-repeat;
    background-position: center;
    inline-size: 24px;
    block-size: 24px;
  }

  button[class*='swiper-button-next'] {
    transform: rotate(180deg);
  }
}
